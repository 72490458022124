import * as $ from 'jquery';
require('url-search-params-polyfill');

export default class iframe {
  constructor (locale) {
    const queryString = window.location.search;
    this.urlParams = new URLSearchParams(queryString);

    this.url;
    this.queryString;

    this.defineUrl();
    this.addLocale(locale);
    this.addMultigraphUuid();
    this.addMedia();
    this.addSrc();
  }

  defineUrl () {
    if (process.env.NODE_ENV === 'development') {
      this.url = 'http://localhost:5000/s/places';
      this.queryString = 'customer_uuid=';

      if (window.location.pathname.indexOf('carte.html') >= 0) {
        this.queryString += '3df33984-59d4-4b70-862f-5626a6ebfcb0';
      } else if (window.location.pathname.indexOf('immo_360.html') >= 0) {
        this.queryString += '71137cfe-a5c8-4ea9-8b7b-affd52e4f3ec';
      }
    } else {
      // this.url = 'https://sde.vivrou.com/s/places';
      // this.queryString = 'customer_uuid=e00270a6-1d52-4d3a-ada3-b1fa71e0ea35';
      this.url = 'https://app.simplanterou.com/s/places';
      this.queryString = 'customer_uuid=';

      if (window.location.pathname.indexOf('carte.html') >= 0) {
        this.queryString += '95ea6cc3-cbbd-4c57-99e0-3dac8c6dd002';
      } else if (window.location.pathname.indexOf('immo_360.html') >= 0) {
        this.queryString += 'eb98a76e-4488-45df-b9f2-2735f3dafab5';
      }
    }
  }

  addLocale (locale) {
    if (this.queryString.indexOf('&locale') < 0) {
      this.queryString += `&locale=${locale}`;
    } else {
      let urlParams = new URLSearchParams(this.queryString);
      urlParams.set('locale', locale);
      this.queryString = urlParams.toString();
    }
  }

  addMultigraphUuid () {
    if (this.urlParams.get('uuid')) {
      this.queryString += `&multigraph_uuid=${this.urlParams.get('uuid')}`;
    }
  }

  addMedia () {
    if (this.urlParams.get('media')) {
      this.queryString += `&media=print`;
    }
  }

  addSrc () {
    $('#iframe').attr('src', `${this.url}?${this.queryString}`);
  }

  changeSrc (locale) {
    this.addLocale(locale);
    $('iframe').get(0).src = `${this.url}?${this.queryString}`;
  }
}
