require('es6-promise').polyfill();
require('isomorphic-fetch');

export default class Translator {
  constructor () {
    if (!localStorage.getItem('locale')) {
      this.locale = this.getUserLanguage();
    } else {
      this.locale = localStorage.getItem('locale');
    }

    this.elements = document.querySelectorAll('[data-i18n]');
    this.altElements = document.querySelectorAll('[data-i18n-alt]');
    this.linkElements = document.querySelectorAll('[data-i18n-link]');

    this.loadLocaleFile();
  }

  getUserLanguage () {
    let lang = navigator.language ? navigator.language : 'en-US';
    return lang.substr(0, 2);
  }

  setLocale (locale) {
    this.locale = locale;
  }

  getLocale () {
    return this.locale;
  }

  loadLocaleFile () {
    fetch(`locales/${this.locale}.json`)
    .then(res => res.json())
    .then(translation => {
      this.translate(translation);
    }).catch(error => {
      console.error(error);
    });
  }

  translate (translation) {
    if (this.elements.length > 0) {
      for(let index = 0; index < this.elements.length; index++) {
        let keys = this.elements[index].dataset.i18n.split('.');

        let text = keys.reduce((obj, i) => {
          return obj[i];
        }, translation);

        if (text) {
          this.elements[index].innerHTML = text;
        } else {
          throw(`Missing translation for ${this.elements[index].dataset.i18n}`);
        }
      }
    }

    if (this.altElements.length > 0) {
      for(let index = 0; index < this.altElements.length; index++) {
        let keys = this.altElements[index].dataset.i18nAlt.split('.');

        let text = keys.reduce((obj, i) => {
          return obj[i];
        }, translation);

        if (text) {
          this.altElements[index].setAttribute('alt', text);
        } else {
          throw(`Missing translation for ${this.altElements[index].dataset.i18n}`);
        }
      }
    }

    if (this.linkElements.length > 0) {
      for(let index = 0; index < this.linkElements.length; index++) {
        let keys = this.linkElements[index].dataset.i18nLink.split('.');

        let text = keys.reduce((obj, i) => {
          return obj[i];
        }, translation);

        if (text) {
          this.linkElements[index].setAttribute('href', text);
        } else {
          throw(`Missing translation for ${this.linkElements[index].dataset.i18n}`);
        }
      }
    }
  }

  changeLocale (locale) {
    this.setLocale(locale);
    localStorage.setItem('locale', locale);
    this.loadLocaleFile();
  }
}
