import * as $ from 'jquery';
import { slick }  from 'slick-carousel';
import Player from '@vimeo/player';

import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import css from '../assets/stylesheets/style.scss';

import Translator from './translator.js';
let translator = new Translator();

import Iframe from './iframe.js';
let iframe = new Iframe(translator.getLocale());

import LogoImage from '../assets/images/simplanterou_logo.svg';
import LogoNeutralImage from '../assets/images/simplanterou_neutral_black.svg';
import LogoImmo360Image from '../assets/images/simplanterou_immo360_black.svg';
import BackgroundImage from '../assets/images/background.jpg';
import ObjectiveImage from '../assets/images/objective.svg';
import FreeImage from '../assets/images/free.svg';
import FastImage from '../assets/images/fast.svg';
import TabletteImage from '../assets/images/tablette_simplanterou.png';
import RapportImage from '../assets/images/rapport_pdf.png';
import ZenImage from '../assets/images/zen_transport.svg';
import Co2Image from '../assets/images/co2.svg';
import ImpactImage from '../assets/images/impact.svg';
import PoigneeMain from '../assets/images/poignee-de-mains.svg';
import EntrepriseAttractive from '../assets/images/entreprise-attractive.svg';
import DataIa from '../assets/images/data_ia.svg';
import PerformanceVentes from '../assets/images/performance-ventes.svg';
import EntenteCommerciale from '../assets/images/entente-commerciale.svg';
import RelocalisationAccompagnees from '../assets/images/relocalisation-accompagnees.svg';
import VivrouImage from '../assets/images/tablette_vivrou.png';
import simplanterouByEaImage from '../assets/images/simplanterou_by_ea.svg';
import simplanterouByEaRhMobilityImage from '../assets/images/simplanterou_by_ea_rh_mobility.svg';
import linkedinImage from '../assets/images/socials/linkedin.svg';
import twitterImage from '../assets/images/socials/twitter.svg';
import guillementOpenImage from '../assets/images/guillemet-open.svg';
import guillementCloseImage from '../assets/images/guillemet-close.svg';
import favico from '../assets/images/simplanterou-fav.svg';
import apercuVideo from '../assets/images/apercu-video.png';
import playBtn from '../assets/images/play-btn.svg';
import arrowDown from '../assets/images/fleche-down.svg';
import slideTop from '../assets/images/slide-top.svg';

import capdigitalImage from '../assets/images/partners-logo/cap-digital-labtop.jpg';
import institutvilledurableImage from '../assets/images/partners-logo/institut-pour-la-ville-durable-IVD-labtop.jpg';
import laetImage from '../assets/images/partners-logo/laet-labtop.jpg';
import minalogicImage from '../assets/images/partners-logo/minalogic-labtop.jpg';
import pulsalysImage from '../assets/images/partners-logo/pulsalys-labtop.jpg';
import salonentrepreuneursImage from '../assets/images/partners-logo/salon-des-entrepreneurs-labtop.jpg';
import yoobakiImage from '../assets/images/partners-logo/yoobaky-labtop.jpg';
import ignfabImage from '../assets/images/partners-logo/ignfab-labtop.png';

import screenshot_hrmobility from '../assets/images/simplanterou_hrmobility.jpg';
import screenshot_immo360 from '../assets/images/simplanterou_immo360.jpg';

import ilabImage from '../assets/images/partners-logo/ilab-logo.png';
import moovinonImage from '../assets/images/partners-logo/movinon-logo.png';

import keolis from '../assets/images/quotes/keolis.svg';

import frImage from '../assets/images/flags/fr.svg';
import gbImage from '../assets/images/flags/gb.svg';


let header = $('header');
let headerHeight = header.outerHeight();
let links = header.find('[data-scroll-activable]')
let scrollItems = links.map((id, elmt) => {
  if ($(`#${$(elmt).data('scroll-activable')}-section`).length > 0) {
    return $(elmt).data('scroll-activable');
  }
});

let previousDisplayedElement;
let lastDisplayedElement;

$(window).scroll(() => {
  let fromTop = $(window).scrollTop() + (headerHeight + 10);

  let alreadyDisplayedElements = scrollItems.map((id, elmt) => {
    if ($(`#${elmt}-section`).offset().top < fromTop) {
      return elmt;
    }
  });

  previousDisplayedElement = lastDisplayedElement;

  lastDisplayedElement = alreadyDisplayedElements[alreadyDisplayedElements.length - 1];

  if (previousDisplayedElement != lastDisplayedElement) {
    if (['recruiters', 'collectivities', 'advisors'].indexOf(lastDisplayedElement) > 0) {
      updateHeaderLinksForSlick();
      previousDisplayedElement = lastDisplayedElement;
    } else {
      header.find(`[data-scroll-activable]`).removeClass('active');
      header.find(`[data-scroll-activable="${lastDisplayedElement}"]`).addClass('active');
      previousDisplayedElement = lastDisplayedElement;
    }
  }
});

function updateHeaderLinksForSlick () {
  header.find(`[data-scroll-activable]`).removeClass('active');

  if ($(`#recruiters-section`).offset().left > 0) {
    header.find(`[data-scroll-activable="recruiters"]`).addClass('active');
  } else if ($(`#collectivities-section`).offset().left > 0) {
    header.find(`[data-scroll-activable="collectivities"]`).addClass('active');
  } else {
    header.find(`[data-scroll-activable="advisors"]`).addClass('active');
  }
}

$('.remark-container', '.main').on('click', () => {
  $('html, body').animate({ scrollTop: $('#testimonies-section').offset().top - $('header').height() }, 1000);
});

$(".partners-slick-slider").slick({
  infinite: true,
  dots: false,
  autoplay: true,
  autoplaySpeed: 3000,
  arrows: false,
  slidesToScroll: 1,
  slidesToShow: 3
});

$(".target-slick-slider").slick({
  infinite: false,
  dots: false,
  autoplay: false,
  arrows: false,
  slidesToScroll: 1,
  slidesToShow: 1
});

$('#burger-menu').on('click', (e) => {
  e.preventDefault();
  $('#mobile-menu').toggleClass('hidden');
});

$('a', '.mobile-menu').on('click', (e) => {
  $('#mobile-menu').toggleClass('hidden');
});

$('.target-slick-slider').on('afterChange', (event, slick, currentSlide) => {
  updateHeaderLinksForSlick();
});

$(`a[data-locale="${translator.getLocale()}"]`, '#language-selector').addClass('hidden');
$('a', '#language-selector').on('click', (event) => {
  event.preventDefault();
  translator.changeLocale(event.currentTarget.dataset.locale);
  $(`a[data-locale="${translator.getLocale()}"]`, '#language-selector').addClass('hidden');
  $(`a:not([data-locale="${translator.getLocale()}"])`, '#language-selector').removeClass('hidden');

  let queryString = window.location;
  if (queryString.pathname.indexOf('carte.html') >= 0) {
    iframe.changeSrc(translator.getLocale());
  }
});

$('#scroll-down-button').on('click', (event) => {
  event.preventDefault();
  $('html, body').animate({ scrollTop: $(`#solution-section`).offset().top - $('header').height() }, 1000);
});

$('#scroll-top-button').on('click', (event) => {
  event.preventDefault();
  $('html, body').animate({ scrollTop: 0 }, 1000);
});

let queryString = window.location;
if (queryString.pathname.indexOf('carte.html') < 0 && queryString.pathname.indexOf('compte_valide.html') < 0) {
  $('a[data-link="section"]').on('click', (event) => {
    event.preventDefault();

    if ($(event.currentTarget).attr('href') === '#slide') {
      scrollAndSlick(event);
    } else {
      let href = `${$(event.currentTarget).attr('href')}-section`;
      $('html, body').animate({ scrollTop: $(href).offset().top - $('header').height() }, 1000);
    }
  });
}

if ($('iframe#iframe-vimeo').length > 0) {
  let vimeoIframe = $('iframe#iframe-vimeo')[0];
  let player = new Player(vimeoIframe);

  $('#video-magnet').off().on('click', () => {
    toggleVideoPopup(true, player);
  });

  $('#video-popup').off().on('click', ({ target }) => {
    if ($(target).hasClass('video-overlay')) {
      toggleVideoPopup(false, player);
    }
  });

  $('#close-video-popup').off().on('click', () => {
    toggleVideoPopup(false, player);
  });
}

function toggleVideoPopup (open, player) {
  if (open) {
    $('#video-popup').removeClass('hidden');
    $('#full-site-container').addClass('blurred');
    player.play();
  } else {
    player.pause();

    setTimeout(() => {
      $('#video-popup').addClass('hidden');
      $('#full-site-container').removeClass('blurred');
    }, 400);
  }
}

function scrollAndSlick (event, slideNumber) {
  if (event) {
    event.preventDefault();
  }

  $('html, body').animate({ scrollTop: $(`#target-promise`).offset().top - 40 }).promise().done(() => {
    let currentElement;
    if (event) {
      currentElement = event.currentTarget;
    }
    if (slideNumber) {
      currentElement = $(`a[data-slide="${slideNumber}"]`)[0];
    }
    goToGoodSlide($(currentElement).data('slide'));
  });
}

function goToGoodSlide (slideNumber) {
  $('.target-slick-slider').slick('slickGoTo', slideNumber - 1);
}

$(document).ready(() => {
  if (queryString.hash && queryString.hash.indexOf('target-promise') >= 0) {
    let slideNumber = queryString.hash[queryString.hash.length-1] * 1;
    setTimeout(() => {
      scrollAndSlick(null, slideNumber);
    }, 400);
  }
});


// cookies management
if (navigator.doNotTrack == 1 || getCookieValue('simplanterou-cookie-consent=') !== true) {
  deleteAllCookies();

  $(`<div></div>`, {
    html: `
      <div class="consent-container">
        <p class="consent-text">En poursuivant votre navigation sur ce site, vous acceptez l'utilisation de cookies afin de vous proposer la meilleure expérience possible. <a href="https://app.simplanterou.com/mentions_legales.html" target="_blank">En savoir plus et paramétrer les cookies.</a></p>
        <div class="cta-button-container">
          <a href="#" class="cta-button" id="cookie-consent-button">Ok</a>
        </div>
      </div>
    `,
    class: 'cookies-layer'
  }).appendTo('body');
}

function deleteAllCookies () {
  let cookies = document.cookie.split(";");
  cookies.forEach((cookie) => {
    let eqPos = cookie.indexOf("=");
    let name = (eqPos >= 0 ? cookie.substr(0, eqPos) : cookie).trim();
    document.cookie = `${name}=;expires=Thu, 01 Jan 1970 00:00:00 GMT`;
  });
}

function getCookieValue (cookieName) {
  return document.cookie.split(';').some( (item) => item.trim().startsWith(cookieName));
}

$('#cookie-consent-button').on('click', (event) => {
  event.preventDefault();
  $('.cookies-layer').remove();

  if (navigator.doNotTrack != 1) {
    document.cookie = 'simplanterou-cookie-consent=true;';
    loadGA();
  }
});

function loadGA () {
  $.getScript('https://www.googletagmanager.com/gtag/js?id=UA-163725842-1');
  window.dataLayer = window.dataLayer || [];
  function gtag(){ dataLayer.push(arguments); }
  gtag('js', new Date());
  gtag('config', 'UA-163725842-1');
}
